import { translateFromLocalizationKey } from '@dar/utils/parseError';
import {
  getProfileFromLocalStorage,
  getWorkspaceFromLocalStorage,
} from '@dar/utils/workspace';
import { toast } from '@dartech/dms-ui';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';

interface CustomAxiosError extends AxiosError {
  localizationKey: string;
}

export const commonExceptionHandler = (error: CustomAxiosError) => {
  const bypassAlertOnErrorCodes = error?.config?.bypassAlertOnErrorCodes;
  if (
    !bypassAlertOnErrorCodes ||
    !bypassAlertOnErrorCodes.includes(`${error.response?.status}`)
  ) {
    toast.error(
      error?.localizationKey
        ? translateFromLocalizationKey(error.localizationKey)
        : error?.message,
      {
        duration: 3000,
      }
    );
  }
  return Promise.reject(error);
};

export const requestHeaderInterceptor = (
  config: InternalAxiosRequestConfig
) => {
  const user = getProfileFromLocalStorage();
  const workspace = getWorkspaceFromLocalStorage();
  const lng = localStorage.getItem('i18nextLng');
  config.headers['dar-dms-user-id'] = user?.id;
  config.headers['dar-dms-org-id'] = workspace?.id;
  config.headers['Workspace-Authorization'] =
    localStorage.getItem('workspace_token');
  config.headers['Accept-Language'] = lng;
  return config;
};
