import { AuthTokens, User, Workspace } from '@dar/api-interfaces';

export const getWorkspaceFromLocalStorage = (): Workspace | null => {
  try {
    return JSON.parse(localStorage.getItem('workspace') as string);
  } catch {
    return null;
  }
};

export const getProfileFromLocalStorage = (): User | null => {
  try {
    return JSON.parse(localStorage.getItem('profile') as string);
  } catch {
    return null;
  }
};

export const getDmsTokensFromLocalStorage = (): AuthTokens | null => {
  try {
    return JSON.parse(localStorage.getItem('dms-auth') as string);
  } catch {
    return null;
  }
};
