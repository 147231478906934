import { useEffect, useState } from 'react';
import { authService } from '@dar-dms/topbar';
import showError from '@dar/services/logger';
import { getWorkspaceToken } from '@dar/services/workspace';
import { getFormatted } from '@dar/utils/timeUtil';
import {
  getProfileFromLocalStorage,
  getWorkspaceFromLocalStorage,
} from '@dar/utils/workspace';

export const useAuthData = () => {
  const [authLoaded, setAuthLoaded] = useState(false);
  const user = getProfileFromLocalStorage();
  const onError = (error: Error) => {
    showError({
      date: getFormatted(new Date(), { isStringDateTimeFormat: true }),
      trace: error.stack,
      url: window.location.href,
      user_id: authLoaded && user ? user.id : '',
      message: error.message,
    }).then((r) => r);
    console.error(
      `FRONT_ERROR ${getFormatted(new Date(), {
        isStringDateTimeFormat: true,
      })} LMS ${authLoaded && user ? user.id : ''} ${window.location.href} ${
        error.message
      } ${error.stack}`
    );
  };
  useEffect(() => {
    if (authService) {
      const sub = authService.loggedIn.subscribe((auth) => {
        if (auth) {
          const workspace = getWorkspaceFromLocalStorage();
          if (workspace) {
            getWorkspaceToken(workspace.id).then((res) => {
              const token = res?.token;

              if (token) {
                localStorage.setItem('workspace_token', res.token);
                setAuthLoaded(true);
              }
            });
          }
        }
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, []);

  return { authLoaded, onError };
};
