import { AssignEndAction, AssignFrom } from './lms';

export enum UserType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface User {
  id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  workEmail: string;
  photoUrl: string | null;
  role?: string;
  phoneMobile?: string;
  email?: string;
  type?: UserType;
  status?: EmployeeStatus;
}

export interface MMUser {
  id: string;
  first_name: string;
  last_name: string;
  nickname: string;
  email: string;
}
export interface Profile {
  avatar: {
    original: string;
    normal: string;
    thumbnail: string;
  };
  bucket_id: string;
  city: string;
  country: string;
  date_of_birth: string | null;
  email: string;
  email_verified: false;
  first_name: string;
  gender: string;
  id: string;
  last_name: string;
  mfa_type: string;
  nickname: string;
  phone_number: string;
  phone_number_verified: boolean;
  pin: string;
  time_zone: string;
  user_identities: {
    bucket_id: string;
    connection_type: string;
    provider: string;
    type: string;
    user_id: string;
  };
}

export interface UserMock {
  id: string;
  avatar: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  logName: string;
  phoneNumber: string;
  role: string;
  company?: string;
}
interface Organization {
  name: string;
  shortName: string;
  logo: string;
  email: string;
  type: string;
}
export interface Workspace {
  id: string;
  name: string;
  isLicensed: boolean;
  bin: string;
  holdingId: string;
  isHolding: boolean;
  isDeputy: boolean;
  employeeId: string;
  type: string;
  organization: Organization;
  numberOfEmployees: number;
  role: string;
}

export interface WorkspaceInfo {
  id: string;
  createdBy: string;
  employeeId: string;
  createdAt: string;
  lastEdit: string;
  information: {
    shortName: string;
    holdingId: string;
  };
}

export interface WorkspaceByHolding {
  id: string;
  shortName: string;
}

export type EmployeeStatus =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'MATERNITY_LEAVE'
  | 'TERMINATED';

export interface Employee {
  employeeId: string;
  status?: EmployeeStatus;
  employeeInfo: {
    name: {
      first: string;
      last: string;
    };
    position: string;
    email: string;
    phoneMobile: string;
    photo: string;
    role: string;
    status?: EmployeeStatus;
  };
}

export interface AssignCourseForm {
  employees: { value: string; label: string }[] | [];
  courses: { value: string; label: string }[] | [];
  startDate: Date | undefined;
  startTime: Date | undefined;
  endDate?: Date;
  endTime?: Date;
  endAction: AssignEndAction;
  assignedFrom: AssignFrom;
  course_id?: string;
  employee_ids?: string[];
}

export interface DirectoryBaseDto {
  id: string;
  holdingId: string;
  localization: {
    ru: string;
    en: string;
  };
  additionalFields: null | Record<string, any>;
  status: string;
  createdAt: string;
  lastEdit: string;
  valuesCount: number;
}
export interface DirectoryDefinitionDTO extends DirectoryBaseDto {
  dictionaryValueIds: string[];
  directoryName: string;
  firstLevel: boolean;
}

export interface DirectoryEntityDTO extends DirectoryBaseDto {
  directoryId: string;
  value: string;
  valueParentId: null;
}

export interface CourseAccessForm {
  workspaces: { id: string; name: string }[] | [];
}

export enum AcceptedLanguages {
  KZ = 'kz',
  RU = 'ru',
  EN = 'en',
  ID = 'bahasa',
  DE = 'de',
}
