import { AuthSubjectValue, AuthTokens, Profile } from '@dar/api-interfaces';
import { environment } from '@dar/environments/environment';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import EventEmitter from 'events';
import { BehaviorSubject } from 'rxjs';

export enum AuthStatus {
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface AuthConfig {
  griffonApiRoot: string;
  griffonBucketId: string;
}

export const LMS_TOKEN_STORAGE_KEY = 'lms-web-auth';
export const PROFILE_STORAGE_KEY = 'lms-web-profile';

const getAuthSubjectValueFromStorage = (): AuthSubjectValue | null => {
  if (typeof window === 'undefined') return null;

  const _profile = localStorage.getItem(PROFILE_STORAGE_KEY);
  const _tokens = localStorage.getItem(LMS_TOKEN_STORAGE_KEY);

  if (!_tokens || !_profile) {
    return null;
  }

  try {
    const profile = JSON.parse(_profile);
    const tokens = JSON.parse(_tokens);
    return {
      profile,
      tokens,
    };
  } catch (e) {
    return null;
  }
};

export class AuthService {
  httpClient: AxiosInstance;

  private tokenRefreshing: Promise<void> | null = null;

  public loginStatus = new EventEmitter();

  private loggedIn = new BehaviorSubject<AuthSubjectValue | null>(
    getAuthSubjectValueFromStorage()
  );
  public loggedIn$ = this.loggedIn.asObservable();

  constructor(private config: AuthConfig) {
    this.httpClient = axios.create();

    this.httpClient.interceptors.response.use(
      (response: AxiosResponse) => response,
      this.refreshTokenErrorInterceptor
    );
  }

  static createInstance(config: AuthConfig) {
    return new AuthService(config);
  }

  public setLoggedInValue = (v: AuthSubjectValue | null) => {
    this.loggedIn.next(v);
  };

  public createHttpClient(axiosConfig?: AxiosRequestConfig): AxiosInstance {
    const httpClient = axios.create(axiosConfig);
    httpClient.interceptors.request.use(this.authTokenRequestInterceptor);
    httpClient.interceptors.response.use(
      (response: AxiosResponse) => response,
      this.refreshTokenErrorInterceptor
    );
    return httpClient;
  }

  private authTokenRequestInterceptor = async (
    config: InternalAxiosRequestConfig
  ) => {
    // Don't override defined header
    if (config.headers?.['Authorization']) {
      return config;
    }
    if (this.tokenRefreshing) {
      await this.tokenRefreshing;
    }
    const tokens = this.getTokens();
    const now = new Date();
    // Token is expired
    if (
      tokens &&
      tokens.expire_date &&
      tokens.expire_date - now.getTime() <= 0
    ) {
      this.tokenRefreshing = new Promise((resolve) => {
        this.refreshTokenAndProfile()
          .catch(() => {
            this.loginStatus.emit(AuthStatus.UNAUTHORIZED, true);
            this.logout();
          })
          .finally(() => {
            resolve();
            this.tokenRefreshing = null;
          });
      });
      await this.tokenRefreshing;
    }
    // Add token to headers
    const idToken = this.getIdToken();
    if (idToken) {
      config.headers.set('Authorization', `Bearer ${idToken}`);
    } else {
      delete config.headers?.['Authorization'];
    }
    return config;
  };

  public getIdToken = (): string | null => {
    let tokens: AuthTokens | null = null;
    const storedTokens = window.localStorage.getItem(LMS_TOKEN_STORAGE_KEY);
    if (!storedTokens) {
      return null;
    }
    try {
      tokens = JSON.parse(storedTokens);
    } catch (e) {
      console.error(e);
    }
    return tokens?.id_token ?? null;
  };

  public getAccessToken = (): string | null => {
    let tokens: AuthTokens | null = null;
    try {
      tokens = JSON.parse(localStorage.getItem(LMS_TOKEN_STORAGE_KEY) || '');
    } catch (e) {
      console.error(e);
    }
    return tokens?.access_token ?? null;
  };

  public persistProfile = (profile: Profile) => {
    localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(profile));
  };

  public persistTokens = (tokens: AuthTokens) => {
    const expire_date = new Date().getTime() + tokens.expires_in * 1000;
    localStorage.setItem(
      LMS_TOKEN_STORAGE_KEY,
      JSON.stringify({
        ...tokens,
        expire_date,
      })
    );
  };

  public getTokens = (): AuthTokens | null => {
    let tokens: AuthTokens | null = null;

    if (typeof window === 'undefined') {
      return null;
    }
    const storedTokens = localStorage.getItem(LMS_TOKEN_STORAGE_KEY);
    if (!storedTokens) {
      return null;
    }
    try {
      tokens = JSON.parse(storedTokens);
    } catch (e) {
      console.error(e);
    }
    return tokens;
  };

  private refreshTokenErrorInterceptor = async (axiosError: AxiosError) => {
    if (
      axiosError.response?.status === 401 ||
      axiosError.response?.status === 403
    ) {
      if (!this.tokenRefreshing) {
        this.tokenRefreshing = new Promise((resolve) => {
          this.refreshTokenAndProfile()
            .then((authInfo) => {
              return authInfo;
            })
            .catch(() => {
              this.loginStatus.emit(AuthStatus.UNAUTHORIZED, true);
              this.logout();
            })
            .finally(() => {
              resolve();
              this.tokenRefreshing = null;
            });
        });
      }
      await this.tokenRefreshing;
      const idToken = this.getIdToken();
      if (idToken) {
        axiosError.config?.headers.set('Authorization', `Bearer ${idToken}`);
      }
      if (axiosError.config) {
        return axios.request(axiosError.config);
      }
    }
    throw axiosError;
  };

  public refreshTokenAndProfile = (refresh_token?: string) => {
    return this.refreshToken(refresh_token).then(async (authInfo) => {
      this.persistTokens(authInfo);
      const profile = await this.getProfile();
      this.persistProfile(profile);
      this.setLoggedInValue({ profile, tokens: authInfo });
      return authInfo;
    });
  };

  public refreshToken = (refresh_token?: string) => {
    const params = new URLSearchParams();
    params.append(
      'refresh_token',
      refresh_token || this.getTokens()?.refresh_token || ''
    );
    params.append('grant_type', 'refresh_token');

    return axios
      .post<AuthTokens>(`${environment.lmsFront}/api/oauth/token`, params)
      .then((res) => res.data);
  };

  public checkIfUserExists = (username: string) => {
    return axios.post<any>(`${this.config.griffonApiRoot}/oauth/signup/check`, {
      username,
      bucket: this.config.griffonBucketId,
    });
  };

  public copyTokensDmsLms = () => {
    const authTokens = localStorage.getItem('dms-auth') as string;
    localStorage.setItem(LMS_TOKEN_STORAGE_KEY, authTokens);
  };

  public getProfile = () => {
    return this.httpClient
      .get<Profile>(`${this.config.griffonApiRoot}/oauth/profile`, {
        headers: {
          Authorization: `Bearer ${this.getIdToken()}`,
        },
      })
      .then((res) => res?.data);
  };

  public logout = () => {
    localStorage.removeItem(PROFILE_STORAGE_KEY);
    localStorage.removeItem(LMS_TOKEN_STORAGE_KEY);
  };
}

export const authLmsService = AuthService.createInstance({
  griffonApiRoot: environment.griffonLmsApiRoot,
  griffonBucketId: environment.griffonLmsBucketId,
});
