import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ErrorBoundary } from '@dar-dms/utils';
import { langType } from '@dar/api-interfaces';
import { Spinner, Toaster } from '@dartech/dms-ui/dist';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuthData } from '../hooks/useAuthData';
import theme from '../theme';
import { router } from './router';

export function App() {
  const { authLoaded } = useAuthData();
  const lang = localStorage.getItem('i18nextLng');

  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
  });

  return (
    <ErrorBoundary moduleName="dar-dms-lms">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<Spinner variant="page" />}>
            {authLoaded && <RouterProvider router={router} />}
          </Suspense>
          <Toaster locale={lang as langType} />
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
